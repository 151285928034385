import Image from "next/image";

const cloudflareLoader = ({ src, width, height, quality }) => {
  let sizes = "";
  sizes += width != undefined ? `,width=${width}` : "";
  sizes += height != undefined ? `,height=${height}` : "";
  return `https://assets.humoq.com/cdn-cgi/image/quality=78${sizes}${src}`;
};

export default function HumoqImage2({ props, src, alt, width, height }) {
  return (
    <Image
      loader={cloudflareLoader}
      layout="fixed"
      alt={alt}
      src={src}
      {...props}
      width={width}
      height={height}
    />
  );
}
